/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { usePagination } from 'hooks';

import Icon from '../Icon/Icon';
import {
  paginationContainerStyles,
  buttonStyles,
  iconStyles,
  numberButtonStyles,
} from './Pagination.styles';

const Pagination = ({
  containerStyles,
  handlePagination,
  totalData,
  siblingCount = 2,
  currentPage,
  totalPage,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalData,
    siblingCount,
    totalPage,
  }) || [];

  if (currentPage === 0) {
    return null;
  }

  return (
    <ul css={[paginationContainerStyles, containerStyles]}>
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={() => handlePagination(1)}
          disabled={currentPage === 1}
        >
          |
          <Icon
            name="chevron-left"
            styles={iconStyles(currentPage === 1)}
          />
          first
        </button>
      </li>
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={() => handlePagination(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <Icon
            name="chevron-left"
            styles={iconStyles(currentPage === 1)}
          />
          prev
        </button>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === '...') {
          return <li key={index}>&#8230;</li>;
        }

        return (
          <li key={index}>
            <button
              type="button"
              css={numberButtonStyles(pageNumber === currentPage)}
              onClick={() => handlePagination(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={() => handlePagination(currentPage + 1)}
          disabled={currentPage === totalPage}
        >
          next
          <Icon
            name="chevron-right"
            styles={iconStyles(currentPage === totalPage)}
          />
        </button>
      </li>
      <li>
        <button
          type="button"
          css={buttonStyles}
          onClick={() => handlePagination(totalPage)}
          disabled={currentPage === totalPage}
        >
          last
          <Icon
            name="chevron-right"
            styles={iconStyles(currentPage === totalPage)}
          />
          |
        </button>
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  containerStyles: PropTypes.shape({}),
  handlePagination: PropTypes.func.isRequired,
  totalData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  containerStyles: css``,
  siblingCount: 2,
};

export default Pagination;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import NotificationContext from 'contexts/notificationContext';
import Layout from 'components/layout/Layout';
import {
  Header,
  CompanyCard,
  SendAgreementModal,
  SuccessSendAgreementModal,
  ChangeStatusModal,
  DeleteModal,
} from 'components/pages/Companies';
import {
  Container,
  Input,
  Icon,
  Pagination,
  Select,
} from 'components/common';
import * as styles from 'styles/pages/Companies/Companies.styles';
import { lastMonth, lastYear } from 'helpers/dateParser/manipulateParser';
import { useDebounce } from 'hooks';
import api from 'api';

const Companies = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const qObject = qs.parse(search) || {};
  const [searchTerm, setSearchTerm] = useState(qObject.q || '');
  const [listCompanies, setListCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: +qObject.page || 1,
    totalPage: 1,
    totalData: 1,
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: '',
    status: '',
  });
  const [agreementForm, setAgreementForm] = useState({
    user: '',
    link: '',
  });
  const [isAgreementModalOpen, setAgreementModal] = useState(false);
  const [isChangeStatusModalOpen, setChangeStatusModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [isSuccessSendAgreementModalOpen, setSuccessSendAgreementModal] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    type: qObject.type || '',
    lastLogin: qObject.filter_last_login || '',
    year: qObject.year || '',
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const debouncedTypeValue = useDebounce(filterQuery.type, 500);
  const debouncedLastLogin = useDebounce(filterQuery.lastLogin, filterQuery.lastLogin === 'custom' ? 5000 : 500);
  const debouncedYear = useDebounce(filterQuery.year, 800);

  const handleChangeType = (e) => {
    setFilterQuery((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOpenAgreementModal = (d) => {
    setSelectedCompany({ id: d.id, status: d.status });
    setAgreementModal(true);
  };
  const handleSendAgreement = async () => {
    try {
      const { data } = await api.sendNdaLink({
        link: agreementForm.link,
        to_user_id: +agreementForm.user,
      });
      if (data.code === 200) {
        setAgreementModal(false);
        setSuccessSendAgreementModal(true);
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    }
  };
  // const handleOpenChangeStatusModal = (id, status) => {
  //   setChangeStatusModal(true);
  //   setSelectedCompany({ id, status });
  // };

  const handleToAddNewCompany = () => navigate('/companies/add-company');

  const handlePagination = (page) => {
    window.scrollTo(0, 0);
    setPagination((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const fetchAvailableCompanies = async (qParams) => {
    try {
      const { data } = await api.getAllCompanies(qParams);
      setListCompanies(data.data.data);
      if (data.data.total_data !== 0 && data.data.data.length === 0) {
        handlePagination(1);
      } else {
        setPagination({
          currentPage: +pagination.currentPage,
          totalPage: data.data.total_page,
          totalData: data.data.total_data,
        });
      }
    } catch (error) {
      if (error.response.status === 500) {
        handlePagination(1);
      }
    }
  };

  const handleExecuteChangeStatus = async () => {
    try {
      const { data } = await api.editCompanyStatus(selectedCompany.id, {
        to_status: selectedCompany.status === 'active' ? 'inactive' : 'active',
      });
      if (data.code === 200) {
        await fetchAvailableCompanies();
        setNotification(true);
        setNotificationContent({
          type: 'success',
          message: data.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setChangeStatusModal(false);
    }
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setSelectedCompany({ id });
  };

  const handleExecuteDelete = async () => {
    try {
      const { data } = await api.deleteCompany(selectedCompany.id);
      if (data.code === 200) {
        await fetchAvailableCompanies();
        setNotification(true);
        setNotificationContent({
          type: 'success',
          message: data.message,
        });
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.message,
      });
    } finally {
      setDeleteModal(false);
    }
  };

  const handleLastLoginValue = () => {
    let res = '';
    if (filterQuery.lastLogin && filterQuery.lastLogin !== 'custom' && filterQuery.lastLogin !== 'never') {
      switch (filterQuery.lastLogin) {
        case 'last-month':
          res = lastMonth();
          break;
        default:
          res = lastYear();
      }
    } else if (filterQuery.year.length === 4 && filterQuery.lastLogin === 'custom') {
      res = `${filterQuery.year}-01-01T00:00:00+07:00`;
    } else if (filterQuery.lastLogin === 'never') {
      res = 'never';
    }
    return res;
  };

  useEffect(() => {
    const queryParam = {
      q: debouncedSearchTerm?.trim() || null,
      page: pagination.currentPage,
      type: debouncedTypeValue || '',
      filter_last_login: debouncedLastLogin || '',
      year: debouncedLastLogin === 'custom' ? debouncedYear : '',
    };
    navigate({
      pathname,
      search: qs.stringify(
        queryParam,
        { skipNull: true, skipEmptyString: true }
      ),
    });
    fetchAvailableCompanies(`?${qs.stringify(
      {
        ...queryParam,
        filter_last_login: handleLastLoginValue(),
      },
      { skipNull: true, skipEmptyString: true }
    )}`);
  }, [debouncedYear,
    debouncedLastLogin, debouncedTypeValue, debouncedSearchTerm, pagination.currentPage]);

  return (
    <Layout>
      <Header handleToAddNewCompany={handleToAddNewCompany} />
      <Container contentContainerStyles={styles.companyContentStyles}>
        <div css={styles.searchJobContainerStyles}>
          <Input
            type="text"
            placeholder="search company"
            styles={styles.inputStyles}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Icon name="search" styles={styles.searchIconStyles} />
        </div>
        <div css={styles.filterContainerStyles}>
          <div css={styles.rowSelectContainerStyles}>
            <Select
              containerStyles={styles.selectContainerStyles}
              styles={styles.selectStyles}
              name="type"
              value={filterQuery.type}
              onChange={handleChangeType}
              options={[
                {
                  label: 'non hiring partner',
                  value: 'non-hiring-partner',
                },
                {
                  label: 'hiring partner',
                  value: 'hiring-partner',
                },
              ]}
              placeholder="select company type"
            />
            <Select
              containerStyles={styles.selectContainerStyles}
              styles={styles.selectStyles}
              name="lastLogin"
              value={filterQuery.lastLogin}
              onChange={handleChangeType}
              options={[
                {
                  label: 'never login',
                  value: 'never',
                },
                {
                  label: 'older than 30 days',
                  value: 'last-month',
                },
                {
                  label: 'older than 1 year',
                  value: 'last-year',
                },
                {
                  label: 'custom year',
                  value: 'custom',
                },
              ]}
              placeholder="last login"
            />
          </div>
          {
            filterQuery.lastLogin === 'custom' && (
              <div css={styles.rowSelectContainerStyles}>
                <Input
                  styles={[styles.inputStyles, styles.inputCustomYearStyles]}
                  name="year"
                  value={filterQuery.year}
                  onChange={handleChangeType}
                  placeholder="enter year"
                />
              </div>
            )
          }
        </div>
        {
          listCompanies.map((d) => (
            <CompanyCard
              key={d.id}
              data={d}
              handleOpenAgreementModal={handleOpenAgreementModal}
              // handleOpenChangeStatusModal={handleOpenChangeStatusModal}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          ))
        }
        <Pagination
          containerStyles={styles.paginationContainerStyles}
          handlePagination={handlePagination}
          totalData={pagination.totalData}
          currentPage={pagination.currentPage}
          totalPage={pagination.totalPage}
        />
        <SendAgreementModal
          isAgreementModalOpen={isAgreementModalOpen}
          setAgreementModal={setAgreementModal}
          selectedCompany={selectedCompany}
          agreementForm={agreementForm}
          setAgreementForm={setAgreementForm}
          handleSendAgreement={handleSendAgreement}
        />
        <SuccessSendAgreementModal
          isSuccessSendAgreementModalOpen={isSuccessSendAgreementModalOpen}
          setSuccessSendAgreementModal={setSuccessSendAgreementModal}
        />
        <DeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setDeleteModal={setDeleteModal}
          handleExecuteDelete={handleExecuteDelete}
        />
        <ChangeStatusModal
          selectedCompany={selectedCompany}
          isChangeStatusModalOpen={isChangeStatusModalOpen}
          setChangeStatusModal={setChangeStatusModal}
          handleExecuteChangeStatus={handleExecuteChangeStatus}
        />
      </Container>
    </Layout>
  );
};

export default Companies;

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { JobDetailHeader, JobDetailCard } from 'components/pages/JobDetail';
import api from 'api';
import { ConfirmationRecommendationModal, SendRecommendationModal, SuccessSendRecommendationModal } from 'components/pages/Jobs/AllJobs';
import NotificationContext from 'contexts/notificationContext';

const JobDetail = () => {
  const { jobId, companyId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const { setNotification, setNotificationContent } = useContext(NotificationContext);
  const [jobData, setJobData] = useState({});
  const [selectedRecommendation, setSelectedRecommendation] = useState({
    jobId: '',
    seekerId: '',
    seekerIds: [],
  });
  const [isRecommendationModalOpen, setRecommendationModal] = useState(false);
  const [isLoadingRecommendation, setLoadingRecommendation] = useState(false);
  const [isConfirmationRecommendationModalOpen,
    setConfirmationRecommendationModal] = useState(false);
  const [isSuccessRecommendationModalOpen, setSuccessRecommendationModal] = useState(false);
  const fetchJobData = async () => {
    const { data } = await api.getJobDetail(companyId, jobId);
    setJobData(data.data);
    setLoading(false);
  };

  const handleOpenRecommendationModal = (recommendJobId) => {
    setSelectedRecommendation((prevState) => ({
      ...prevState,
      jobId: recommendJobId,
    }));
    setRecommendationModal(true);
  };

  const handleSendRecommendation = (seekerId) => {
    setRecommendationModal(false);
    setSelectedRecommendation((prevState) => ({
      ...prevState,
      seekerId,
    }));
    setConfirmationRecommendationModal(true);
  };

  const handleMultipleRecommendationModal = () => {
    setRecommendationModal(false);
    setConfirmationRecommendationModal(true);
  };

  const handleExecuteRecommendation = async () => {
    const payload = {
      seeker_ids: selectedRecommendation.seekerIds.length
        ? selectedRecommendation.seekerIds : [selectedRecommendation.seekerId],
    };
    try {
      setLoadingRecommendation(true);
      const { data } = await api.sendRecommendation(selectedRecommendation.jobId, payload);
      if (data.code === 200) {
        setConfirmationRecommendationModal(false);
        setSuccessRecommendationModal(true);
        setSelectedRecommendation((prevState) => ({
          ...prevState,
          seekerId: '',
          seekerIds: [],
        }));
      }
    } catch (error) {
      setNotification(true);
      setNotificationContent({
        type: 'error',
        message: error.response.data.errors[0],
      });
    } finally {
      setLoadingRecommendation(false);
      setConfirmationRecommendationModal(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  return (
    <Layout>
      <JobDetailHeader
        data={jobData}
        jobId={jobId}
        companyId={companyId}
        handleOpenRecommendationModal={handleOpenRecommendationModal}
      />
      {
        isLoading ? <div /> : <JobDetailCard data={jobData} />
      }
      <SendRecommendationModal
        isRecommendationModalOpen={isRecommendationModalOpen}
        setRecommendationModal={setRecommendationModal}
        handleSendRecommendation={handleSendRecommendation}
        handleMultipleRecommendationModal={handleMultipleRecommendationModal}
        selectedRecommendation={selectedRecommendation}
        setSelectedRecommendation={setSelectedRecommendation}
      />
      <ConfirmationRecommendationModal
        isLoadingRecommendation={isLoadingRecommendation}
        isConfirmationRecommendationModalOpen={isConfirmationRecommendationModalOpen}
        setConfirmationRecommendationModal={setConfirmationRecommendationModal}
        handleClick={handleExecuteRecommendation}
      />
      <SuccessSendRecommendationModal
        isSuccessRecommendationModalOpen={isSuccessRecommendationModalOpen}
        setSuccessRecommendationModal={setSuccessRecommendationModal}
        setRecommendationModal={setRecommendationModal}
      />
    </Layout>
  );
};

export default JobDetail;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import {
  ShortText,
  Icon,
  Pill,
  Button,
  NavLink,
} from 'components/common';
import companyLogo from 'assets/placeholder/company-logo-placeholder.jpeg';
import {
  jobsCardDeadlineParser as dateParser,
  humanDateSlashesConverter,
} from 'helpers';

import * as styles from './JobCard.styles';

const JobCard = ({
  job,
  handleOpenChangeStatusModal,
  handleOpenRecommendationModal,
}) => {
  const handlePillColor = (status) => {
    if (status === 'open') {
      return 'LIGHT_BLUE';
    }
    if (status === 'closed') {
      return 'PRIMARY_YELLOWISH_ORANGE';
    }
    return 'GREY';
  };

  return (
    <PlainCard styles={styles.containerStyles}>
      <div css={styles.leftContainerStyles}>
        <div css={styles.imageContainerStyles}>
          <img css={styles.imageStyles} src={job?.company?.logo_url || companyLogo} alt="company-logo" />
        </div>
        <div css={styles.companyContentStyles}>
          <div css={styles.jobApplicantContainerStyles}>
            <ShortText>Job Posted at</ShortText>
            &nbsp;
            <ShortText>{humanDateSlashesConverter(job.last_status_at.open) || ''}</ShortText>
          </div>
          <NavLink
            styles={styles.jobTitleStyles}
            path={`/jobs/${job.id}/job-detail/${job.company.id}`}
            target="_blank"
          >
            {job.name || ''}
          </NavLink>
          <NavLink to={`/companies/${job.company.id}/company-detail?`} target="_blank" styles={{ textDecoration: 'none', margin: '6px 0px' }}>
            <ShortText styles={styles.jobCompanyStyles}>{job.company?.name}</ShortText>
          </NavLink>
          {
            job.province?.label ? (
              <ShortText styles={styles.jobLocationStyles}>
                {`${job.province?.label}, ${job.country?.label}`}
              </ShortText>
            ) : ''
          }
          <div css={styles.jobApplicantContainerStyles}>
            <Icon name="user" />
            <ShortText>
              {job.seekers_applied || 0}
              &nbsp;
              applicants
            </ShortText>
          </div>
          <div css={styles.bottomContainerStyles}>
            <Pill styles={styles.pillStyles(handlePillColor(job.status))}>
              {job.status || ''}
            </Pill>
            {
              job.status !== 'draft' && (
                <Button
                  type="button"
                  styles={styles.changeStatusButtonStyles}
                  onClick={
                    () => handleOpenChangeStatusModal(job.id, job.company.id, job.name, job.status)
                  }
                >
                  <ShortText>change status</ShortText>
                </Button>
              )
            }
            <Icon name="clock" styles={styles.clockIconStyles} />
            <div css={styles.deadlineContainerStyles}>
              <ShortText>deadline:</ShortText>
              &nbsp;
              <ShortText>{dateParser(job.due_date) || ''}</ShortText>
            </div>
          </div>
        </div>
      </div>
      <div css={styles.buttonContainerStyles}>
        {
          job.status !== 'closed' && (
            <NavLink
              styles={styles.navlinkStyles}
              path={`/jobs/${job.id}/edit-job/${job.company.id}`}
              state={{ companyId: job.company.id, jobId: job.id }}
            >
              edit
            </NavLink>
          )
        }
        <NavLink
          path={`/jobs/${job.id}/applicants/${job.company.id}`}
          state={{ companyId: job.company.id }}
          className="middle"
          styles={styles.navlinkStyles}
        >
          details
        </NavLink>
        {
          job.status === 'open' && (
          <Button onClick={() => handleOpenRecommendationModal(job.id)}>
            recommend
          </Button>
          )
        }
      </div>
    </PlainCard>
  );
};

JobCard.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number,
    due_date: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      logo_url: PropTypes.string,
    }),
    city: PropTypes.shape({
      label: PropTypes.string,
    }),
    province: PropTypes.shape({
      label: PropTypes.string,
    }),
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    seekers_applied: PropTypes.number,
    last_status_at: PropTypes.shape({
      closed: PropTypes.instanceOf(Date),
      open: PropTypes.instanceOf(Date),
    }),
  }).isRequired,
  handleOpenChangeStatusModal: PropTypes.func.isRequired,
  handleOpenRecommendationModal: PropTypes.func.isRequired,
};

export default JobCard;

/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import {
  Container, ShortText, Button, NavLink,
} from 'components/common';

import colors from 'config/color';
import {
  containerStyles,
  contentContainerStyles,
  navigationContainerStyles,
  textStyles,
  postJobButtonStyles,
} from './JobDetailHeader.styles';

const JobDetailHeader = ({
  data, jobId, companyId, handleOpenRecommendationModal
}) => (
  <Container containerStyles={containerStyles} contentContainerStyles={contentContainerStyles}>
    <div css={navigationContainerStyles}>
      <ShortText styles={textStyles}>job details</ShortText>
    </div>
    <div style={{ display: 'flex', gap: '10px' }}>
      <Button styles={postJobButtonStyles}>
        <NavLink
          path={`/jobs/${jobId}/edit-job/${companyId}`}
          state={{ data }}
        >
          edit detail
        </NavLink>
      </Button>
      {data.status === 'open' && (
        <Button onClick={() => handleOpenRecommendationModal(jobId)} styles={{ color: `${colors.WHITE} ` }}>
          send recommendation
        </Button>
      )}
    </div>
  </Container>
);

JobDetailHeader.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  jobId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  handleOpenRecommendationModal: PropTypes.func.isRequired,
};

export default JobDetailHeader;

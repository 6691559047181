/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { PlainCard } from 'components/cards';
import icon from 'assets/candidates/job-icon.png';
import otherPortalBadge from 'assets/jobs/other-portal-badge.png';
import { ShortText, NavLink } from 'components/common';
import { jobsCardDeadlineParser } from 'helpers';
import * as styles from './JobCard.styles';

const JobCard = ({ data }) => {
  const handleBackgroundColor = () => {
    let color = '';
    switch (data.application.status) {
      case 'offer':
        color = '#EAB600';
        break;
      case 'hired':
        color = '#1EBD4A';
        break;
      case 'rejected':
        color = '#F16634';
        break;
      default:
        color = '#1E5BBD';
    }
    return color;
  };

  return (
    <PlainCard styles={styles.cardStyles}>
      <div css={styles.imageContainerStyles}>
        <img src={data.company.banner_url || icon} alt="logo" css={styles.imageStyles} />
        {
          data.portal.id !== 'system' && (
            <img src={otherPortalBadge || icon} alt="logo" css={styles.otherPortalImageStyles} />
          )
        }
      </div>
      <div css={styles.companyContainerStyles}>
        <div css={styles.firstLineStyles}>
          {data.portal.id === 'system' ? (
            <NavLink
              path={`/jobs/${data.id}/job-detail/${data.company.id}`}
              style={{ textDecoration: 'none' }}
            >
              <ShortText styles={styles.jobTitleStyles}>
                {data.name}
              </ShortText>
            </NavLink>
          ) : (
            <ShortText styles={styles.jobTitleStyles}>
              {data.name}
            </ShortText>
          )}

          <ShortText styles={styles.pillStyles(handleBackgroundColor())}>
            {data.application.status}
          </ShortText>
        </div>
        {data.portal.id === 'system' ? (
          <NavLink
            path={`/companies/${data.company.id}/company-detail?`}
            style={{ textDecoration: 'none', margin: '6px 0px' }}
          >
            <ShortText styles={styles.companyStyles}>
              {data.company.name}
            </ShortText>
          </NavLink>
        ) : (
          <ShortText styles={styles.companyStyles}>
            {data.company.name}
          </ShortText>
        )}
        {
          data.application.applied_at && (
          <ShortText styles={styles.appliedStyles}>
            {`applied on ${jobsCardDeadlineParser(data.application.applied_at)}`}
          </ShortText>
          )
        }
      </div>
    </PlainCard>
  );
};

JobCard.propTypes = {
  data: PropTypes.shape({
    company: PropTypes.shape({
      banner_url: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    application: PropTypes.shape({
      applied_at: PropTypes.string,
      status: PropTypes.string,
    }),
    portal: PropTypes.shape({
      id: PropTypes.string,
    }),
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default JobCard;
